import React from 'react';
import { string } from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { Box } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';

import theme from 'theme';

const ALT = 'Total Loyalty, register for an account.';

const RegistrationSquare = () => {
 
  const data = useStaticQuery(graphql`
   query {
     all: file(relativePath: { eq: "square/reg_1.png" }) {
       childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
     }
   }
 `);

  return (
    <Box boxShadow={2}>
      <GatsbyImage
        style={{
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(-1),
          width: '100%',
          height: 'auto',
        }}
        imgStyle={{ width: '100%', height: 'auto' }}
        image={data.all.childImageSharp.gatsbyImageData}    
        alt={ALT}
        />
    </Box>
  );
};

RegistrationSquare.propTypes = { width: string.isRequired };

export default RegistrationSquare;
